import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Button, CardTitle, CardText, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import  { StaticQuery } from "gatsby"
import './_slideshow.scss'

export default class SlideShow extends React.Component {
    
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  toggle(tab, count) {
    if (this.state.activeTab !== tab) {
      if(tab >= 0 && tab <= count-1) {
        this.setState({
          activeTab: tab
        })
      }
    }
  }

  handleChange = (event) => {
    const tab = parseInt(event.target.value)
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }  

  render() {
    return (
      <StaticQuery
      query={graphql`
      query {
        allMarkdownRemark
        (
          filter: {
              fileAbsolutePath: {
                regex: "/slideshow-instances/"
              }
            },
          sort: { order: ASC, fields: [frontmatter___order]}
        )
        {
          edges {
            node {
              html
              fileAbsolutePath
              frontmatter {
                title
                image {
                    publicURL
                }
                altText
              }
            }
          } 
        }
      }
    `}
      render={data => {
        const results = data.allMarkdownRemark.edges;
        let images = [];
        results.forEach((item) => ((item.node.fileAbsolutePath.includes(this.props.slideShowDir))) ? images.push(item) : null);
        return (
        <div className="slides">
          {this.props.title && (
            <h1 className="text-center primary">{this.props.title}</h1>
          )}
            <Card>
              <CardBody>
                <Col xs={12} className="pull-right">
                {images.map(({node}, index) => (
                  <span>
                    <h4 
                    {...this.state.activeTab !== index && {className: "d-none"}}
                    dangerouslySetInnerHTML={{ __html: node.html }}/>
                  </span>
                ))}
                  <TabContent activeTab={this.state.activeTab}>
                  {images.map(({node}, index) =>(
                    <TabPane tabId={index}>
                        <Row>
                        <Col sm="12">
                            <div className="text-center pb-2 imageContainer">
                              <img alt={node.frontmatter.altText} className="slideImage" src={node.frontmatter.image.publicURL} />
                              <a 
                              onClick={() => { this.toggle(index-1, images.length); }} 
                              className="prev-slide slide-button" 
                              title="Previous image"
                              >&lt;</a>
                              <a 
                              onClick={() => { this.toggle(index+1, images.length); }} 
                              className="next-slide slide-button" 
                              title="Next image"
                              >&gt;</a>
                            </div>
                            <div className="slideShowFooter"></div>
                        </Col>
                        </Row>
                    </TabPane>
                  ))}
                  </TabContent>
                </Col>
                
                <div className="pt-3">
                  <div className="thumbs">
                    <Col xs={12} className="pull-left">
                      <Nav className="flex-column tenSteps">
                        {images.map(({node}, index) =>(
                          <NavItem>
                            <NavLink
                            {...this.state.activeTab === index && {className: "activeTab"}}
                            onClick={() => { this.toggle(index, images.length); }}
                            >
                            <img alt={node.frontmatter.altText} className="thumbnailImage" src={node.frontmatter.image.publicURL} />
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                      {/*
                      <a onClick={scrollBack()} id="thumb-back" className="arrow back" title="Previous page">&lt;</a>
                      <a onClick={scrollForward()} id="thumb-forward" className="arrow forward" title="Next page">&gt;</a>
                      */}
                    </Col>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        )
      }
    }
    />
    )
  }
}
