import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Card, CardTitle, CardBody, CardImg, Col } from 'reactstrap'
import SlideShow from "../../slideshow-instances/slideshow-floor-2/slideshow-floor-2"

const Floor = () => (
  <StaticQuery 
    query={floorQuery} 
    render= {data => { 
      return (
        <div>
          <Content data={data}/>
        </div>
      )
    }}
    />
)

const Content = ({data}) => {
  const content = data.markdownRemark.frontmatter;
  return (
      <Card>
        <CardBody className='post-item'>
            <Col sm={12}> 
                <Col xs={12} sm={6} className='pull-left'>   
                  <CardTitle>
                      <h4>{content.title}</h4>
                  </CardTitle> 
                  <CardImg bottom alt={content.altText} width='100%' src={content.image.publicURL} />
                </Col>
                <Col xs={12} sm={6} className='pull-right'>
                    <SlideShow/>
                </Col>
            </Col>
        </CardBody>
  </Card>
  )
}

const floorQuery = graphql`
  query {
    markdownRemark(fileAbsolutePath: {regex: "/floors/floor-2/content/markdown/"}) {
      id
      frontmatter {
        title
        image {
          publicURL
        }
        altText
      }
      html
    }
  }
`

export default Floor
