import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import Floor from "../components/floors/floor-2/floor"

const FirstFloorPage = () => (
  <Layout pageTitle={false} sideBar={false}>
    <SEO title="Office Rentals in Bristol - Cotham, First Floor" />
    <Floor/>
  </Layout>
)

export default FirstFloorPage
 